import React from "react";
import FunctionPageLayout from "../components/functionPageLayout";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import { FaUser, FaBook, FaCheckSquare, FaHistory } from "react-icons/fa";
import Columns from "../components/columns";
import BenefitBlock from "../components/benefitBlock";
import SEO from "../components/seo";
import Lightbox from "../components/lightBox";
import BenefitBlockWrapper from "../components/BenefitBlockWrapper";
import TryButton from "../components/TryButton";

export const query = graphql`
  query {
    kz1: file(relativePath: { eq: "prosty-crm-kalendarz.png" }) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    kz2: file(relativePath: { eq: "Kalendarz-w-prostym-systemie-CRM.png" }) {
      childImageSharp {
        fluid(maxWidth: 1349, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    kz3: file(relativePath: { eq: "Zadania-w-prostym-systemie-CRM.png" }) {
      childImageSharp {
        fluid(maxWidth: 1349, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

const KalendarzZadania = ({ data }) => {
  return (
    <FunctionPageLayout
      title="Planowanie i koordynacja pracy"
      headline="Płynne funkcjonowanie działu sprzedaży"
    >
      <SEO
        title="Kalendarz i zadania w prostym CRM - planuj i koordynuj ☑"
        description="Kalendarz i zadania w prostym systemie CRM • Planuj pracę zespołu • Wgląd do bazy zadań • Wyszukuj zadania pracowników • Pierwsze 30 dni dni za darmo!"
        keywords={["kalendarz i zadania"]}
      />
      <Columns>
        <div>
          <p>
            <strong>
              QuickCRM to narzędzie, które ułatwia planowanie i&nbsp;koordynację
              pracy.
            </strong>{" "}
            <strong>
              Dzięki wbudowanemu kalendarzowi, możesz zarządzać zadaniami
              i&nbsp;zdarzeniami.
            </strong>
          </p>
          <p>
            Zadania możesz delegować dla swoich pracowników, lub mogą oni
            tworzyć je sami. Każde zadanie może być przypisane do szansy i/lub
            klienta, co zapewni&nbsp;{" "}
            <strong>porządek w&nbsp;zarządzaniu pracą całego zespołu</strong>.
            Typy zadań możesz ustalać dowolnie — np. planowane spotkanie,
            wysyłka oferty czy kontakt telefoniczny. Wykonane zadanie zmienia
            się w&nbsp;zdarzenie i&nbsp;także jest przechowywane
            w&nbsp;systemie, więc z&nbsp;łatwością sprawdzisz, co danego dnia
            działo się w&nbsp;firmie.
          </p>
        </div>
        <Img
          style={{
            maxWidth: 800,
            margin: "0 auto"
          }}
          fluid={data.kz1.childImageSharp.fluid}
        />
      </Columns>
<br/ >
      <h3>Jakie zadania na dziś?</h3>
      <br />
      <p>
        Kalendarz i&nbsp;zadania w&nbsp;QuickCRM umożliwiają&nbsp;
        <strong>sprawne planowanie pracy&nbsp;całego zespołu</strong>.
        W&nbsp;kalendarzu możesz&nbsp;
        <strong>
          wyświetlać swoje zadania, a&nbsp;także zadania innych pracowników
        </strong>
        , rozróżniając je dowolnymi kolorami. Dzięki temu każdy pracownik, zaraz
        po przyjściu do pracy, zobaczy swoje zadania na dany dzień i&nbsp;będzie
        mógł od razu zabrać się do ich wykonania.
      </p>
                                          <br />
      <br />
      <h3>Deleguj zadania</h3>
      <br />
      <br />
      <p>
        Jeśli chcesz&nbsp;<strong>przypisać pracownikowi zadanie</strong>,
        możesz bez kontaktu z&nbsp;nim zobaczyć, jakie aktualnie wykonuje
        czynności&nbsp;i&nbsp;ocenić czy może zająć się kolejnym tematem.&nbsp;
        <strong>
          Zadania mogą zostać przypisane do jednej lub wielu osób.&nbsp;
        </strong>
        Oszczędza to czas, który trzeba byłoby poświęcić na informowanie
        wszystkich zainteresowanych o&nbsp;nowym zadaniu np.&nbsp; planowanym
        spotkaniu.
      </p>
      <p>
        W naszym systemie CRM znajdziesz również
        <strong>&nbsp;listę aktywności</strong>. Za pomocą intuicyjnej
        wyszukiwarki możesz odnaleźć zadania i&nbsp;zdarzenia przypisane do
        konkretnej osoby lub wszystkich pracowników. Lista aktywności pozwala
        w&nbsp;szybki sposób zapoznać się ze wszystkimi zadaniami, które
        zrealizował dany pracownik oraz z&nbsp;pełnym ich opisem.
      </p>
             <Lightbox
        style={{
          maxWidth: 800,
          margin: "0 auto"
        }}
        images={[data.kz2, data.kz3]}
        alts={["kalendarz i zadania, wyszukiwarka kalendarz i zadania w crm"]}
      />
      <h3
        style={{
          textAlign: "center"
        }}
      >
      <br />
      <br />
        <strong>Co zyskujesz?</strong>
        <br />
        <br />
      </h3>
      <BenefitBlockWrapper>
        <BenefitBlock
          icon={<FaUser size="45px" />}
          content="Delegowanie zadań dowolnym pracownikom"
        />
        <BenefitBlock
          icon={<FaBook size="45px" />}
          content="Podgląd zadań i zdarzeń wybranych pracowników"
        />
        <BenefitBlock
          icon={<FaCheckSquare size="45px" />}
          content="Kontrolę wykonywanych zadań"
        />
        <BenefitBlock
          icon={<FaHistory size="45px" />}
          content="Dostęp do historii zadań i zdarzeń"
        />
      </BenefitBlockWrapper>
    </FunctionPageLayout>
  );
};

export default KalendarzZadania;
